// component
import React from 'react'
import { Link, Box, Button } from '@material-ui/core'
import InstagramIcon from '@material-ui/icons/Instagram'
// page
import './Ops.scss'
import brand from '../../assets/img/brand/brand.svg'
import brandName from '../../assets/img/brand/brandname-light.svg'
import akali3d from '../../assets/img/home-akali-3d-memoji.png'
// team memoji
import pedro from '../../assets/img/memojis/pedro/dev.png'
import Triangle from '../../components/memojiBG/Triangle'

const Ops = () => (
  <div className='Ops' data-testid='Ops'>
    <Box className='content' display='flex' alignItems='center'>
      <div className='box-text'>
        <Box
          className='brand'
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <img src={brand} className='brand-img' />
          <img src={brandName} className='brand-name' />
        </Box>
        <h1 className='text-light'>Coming Soon!</h1>
      </div>

      <div className='box-img'>
        <img src={akali3d} className='3d-img' />
      </div>
    </Box>

    <div className='social-media text-light'>
      <p>Stay tuned on our social media!</p>
      <Link
        href='https://www.instagram.com/akaliconsulting/'
        target='_blank'
        rel='noopener noreferrer'
      >
        <Button variant='contained' className='btn-gd2'>
          <InstagramIcon />
        </Button>
      </Link>
    </div>

    <div className='developed'>
      <Link
        href='https://www.behance.net/pedrohymino'
        target='_blank'
        rel='noopener noreferrer'
      >
        <p>Developed by</p>
        <div className='dev-profile'>
          <img src={pedro} />
          <Triangle />
        </div>
      </Link>
    </div>
  </div>
)

export default Ops
