// React Imports
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Container } from '@material-ui/core'

// Index Imports
import './index.scss'
// import Header from './components/Header/Header'
// import Footer from './components/Footer/Footer'
// import Home from './components/Home/Home'
// import About from './components/About/About'
// import Taxreturn from './components/Taxreturn/Taxreturn'
// import Example from './components/Example/Example'
import Ops from './components/Ops/Ops'

ReactDOM.render(
  <Container maxWidth='lg'>
    <Router>
      {/* <Header /> */}
      <Switch>
        {/* <Route exact path='/' component={Home} />
        <Route path='/about' component={About} />
        <Route path='/taxreturn' component={Taxreturn} />
        <Route path='/dev' component={Example} /> */}
        <Route path='*' component={Ops} />
      </Switch>
      {/* <Footer /> */}
    </Router>
  </Container>,
  document.getElementById('root')
)
