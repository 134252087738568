/* eslint-disable */
import './Triangle.scss'
const Triangle = () => {
  return (
    <div class='bg-triangle'></div>
    // <svg
    //   id='bg-triangle'
    //   data-name='BG Triangle'
    //   xmlns='http://www.w3.org/2000/svg'
    //   viewBox='0 0 111.95 111.11'
    // >
    //   <path d='M28.78,16.82l48.53-12,16.33-4C107-2.57,114.79,5.53,111,18.75l-4.66,16.16L92.48,83,87.82,99.12c-3.8,13.22-14.72,15.92-24.26,6L51.89,93,17.2,57,5.53,44.89C-4,35-.89,24.18,12.45,20.87Z' />
    // </svg>
  )
}

export default Triangle
